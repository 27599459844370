import { Controller } from 'stimulus'

/**
 * @param {Date} date
 */
const toEndOfDay = (date) => {
  date.setHours(23, 59, 59, 999)
  return date
}

/**
 * @param {HTMLInputElement} element
 * @param {String} locale
 */
const initializeDatepicker = (element, locale) => {
  $(element).datepicker({
    format: 'yyyy-mm-dd',
    language: locale,
    autoclose: true,
    endDate: toEndOfDay(new Date())
  })

  if (element.value !== '') {
    $(element).datepicker('update', element.value.slice(0, 10))
  }
}

export default class extends Controller {
  static targets = ['from', 'to']

  connect() {
    initializeDatepicker(this.fromTarget, this.data.get('locale'))
    initializeDatepicker(this.toTarget, this.data.get('locale'))

    $(this.fromTarget).datepicker().on('changeDate', () => this.handleFromDateChange())
    $(this.toTarget).datepicker().on('changeDate', () => this.handleToDateChange())
  }

  get fromDate() {
    return this.fromTarget.value ? new Date(this.fromTarget.value) : null
  }

  get toDate() {
    return this.toTarget.value ? new Date(this.toTarget.value) : null
  }

  handleFromDateChange() {
    if (this.fromDate === null || this.toDate === null) {
      return
    }

    if (this.toDate < this.fromDate) {
      $(this.toTarget).datepicker('setDate', this.fromDate)
    }
  }

  handleToDateChange() {
    if (this.fromDate === null || this.toDate === null) {
      return
    }

    if (this.fromDate > this.toDate) {
      $(this.fromTarget).datepicker('setDate', this.toDate)
    }
  }
}
