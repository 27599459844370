import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal', 'list', 'counterpartySelection']

  /**
   * Action that shows modal for specific list.
   *
   * This action must be attached to element which has list-name attribute in the dataset.
   *
   * @example
   * <div data-list-name="promotion-items" data-action="click->promotions-items#showModal">Dodaj towary</div>
   */
  showModal(event) {
    const listName = event.currentTarget.dataset.listName
    this.modalTarget.dataset.currentList = listName
    this.modalTarget.dataset.specialPriceItems = event.currentTarget.dataset.specialPriceItems

    this.modal.show()
  }

  controllerFor(listName) {
    const listElement = this.listTargets.find(listTarget => listTarget.dataset.name === listName)
    const controller = this.application.getControllerForElementAndIdentifier(listElement, 'dynamic-list')

    return controller
  }

  get modal() {
    return this.application.getControllerForElementAndIdentifier(this.modalTarget, 'items-modal')
  }
}
