import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['quantity', 'weight']

  connect() {
    this.multiply_weight()
    this._setStep()
  }

  multiply_weight() {
    const field = this.quantityTarget
    const amount = field.value

    this.weightTarget.value = this._multiply_values(this.unitWeight, amount)
  }

  multiply_quantity() {
    const field = this.weightTarget
    const weight = field.value

    this.quantityTarget.value = this._divide_values(weight, this.unitWeight)
  }

  _setStep() {
    if (this.pack || this.szt) {
      $(this.quantityTarget).attr('step', 1)
      $(this.weightTarget).attr('step', this.unitWeight)
    }
  }

  get unitWeight() { return this.pack ? this.packageWeight : this.weight }

  get pack() { return this.quantityTarget.dataset.package == 'true' }

  get szt() { return this.quantityTarget.dataset.unit == 'szt' }

  get packageWeight() { return this.quantityTarget.dataset.packageWeight }

  get weight() { return this.quantityTarget.dataset.weight }

  _multiply_values(base, amount) {
    const result = (base * amount).toFixed(2)
    if (isNaN(result)) {
      return 0
    }

    return result
  }

  _divide_values(x, y) {
    const result = (x / y).toFixed(2)
    if (isNaN(result)) {
      return 0
    }

    return result
  }
}
