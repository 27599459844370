import { Controller } from 'stimulus'


export default class extends Controller {
  formIsChanged() {
    this.setChanged('true')
  }

  leavingPage(event) {
    if (this.isFormChanged()) {
        event.returnValue = 1
        return event.returnValue
    }
  }

  allowFormSubmission() {
    this.setChanged('false')
  }

  setChanged(changed) {
    this.data.set('changed', changed)
  }

  isFormChanged() {
    return this.data.get('changed') == 'true'
  }
}
