import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    this.pagination.addEventListener('page-changed', () => this.filterItems())
  }

  show() {
    this.filterItems()
    $(this.element).find('.modal').modal('show')
  }

  filterItems() {
    this._fetchItems(null)
      .then(json => {
        this._setPagination(json.pagination)
        this._setData(json.items)
        this._enableFilters()
      })
  }

  changeItemSelection(event) {
    const currentListController = this.getCurrentListController()
    const entry = this.listController.entries[event.currentTarget.dataset.entryItemId].data

    if (event.currentTarget.checked) {
      currentListController.addEntry(Object.assign({}, entry, { promotion_item_id: null, item_id: entry.id, quantity: 1 }))
      this.showToast('success', entry.common_name)
    } else {
      currentListController.removeEntry(entry)
      this.showToast('danger', entry.common_name)
    }
  }

  showToast(type, name) {
    const date = Date.now()
    const text = type === 'success' ? 'Dodano' : 'Usunięto'
    $('.toasts-containter-items').append(
      `<div class="toast" id=${date} role="alert" aria-live="assertive" aria-atomic="true" data-delay="4000">
        <div class="toast-header bg-${type} text-white">
          <strong class="mr-auto">${text}</strong>
        </div>
        <div class="toast-body">
          ${name}
        </div>
      </div >`
    )

    const elem = $(`#${date}`)
    elem.toast('show')
    elem.on('hidden.bs.toast', () => {
      elem.remove()
    })
  }

  formData(page) {
    const formData = new FormData($('#promotions-items-filter-form')[0])
    const result = { page: page ? page : this.pagination.dataset.page, accepted_true: 1 }

    for (const entry of formData.entries()) {
      if (entry[1] !== '') {
        result[entry[0]] = entry[1]
      }
    }

    return result
  }

  get pagination() {
    return $(this.element).find('#pagination')[0]
  }

  get paginationController() {
    return this.application.getControllerForElementAndIdentifier(this.pagination, 'pagination')
  }

  get listController() {
    return this.application.getControllerForElementAndIdentifier($(this.element).find('#filtered_items')[0], 'dynamic-list')
  }

  get promotionsItemsController() {
    return this.application.getControllerForElementAndIdentifier(this.element.parentElement, 'promotions-items')
  }

  getCurrentListController() {
    return this.promotionsItemsController.controllerFor(this.element.dataset.currentList)
  }

  async addAll() {
    const firstPageResult = await this._fetchItems(1)
    let currentPage = 2
    const totalPages = firstPageResult.pagination.total

    let items = firstPageResult.items

    while (currentPage <= totalPages) {
      const nextPage = await this._fetchItems(currentPage)
      items = items.concat(nextPage.items)
      currentPage += 1
    }

    const currentListController = this.getCurrentListController()

    items.forEach(entry => {
      currentListController.addEntry(Object.assign({}, entry, { promotion_item_id: null, item_id: entry.id, quantity: 1 }))
    })

    // Set modal to first page
    this._setPagination(firstPageResult.pagination)
    this._setData(firstPageResult.items)
    this._enableFilters()
  }

  _setData(entries) {
    this.listController.clear()
    const currentListController = this.getCurrentListController()

    entries.forEach(entry => {
      this.listController.addEntry({
        checked: currentListController.entries[entry.id] !== undefined,
        ...entry
      })
    })

    $('input[data-checked="true"]').prop('checked', true)

    this.paginationController.render()
  }

  async _fetchItems(page) {
    return fetch(`/admin/items?${new URLSearchParams(this.formData(page))}`, { method: 'get', headers: { accept: 'application/json' } })
      .then(response => response.json())
  }

  _setPagination({ current, total }) {
    this.pagination.dataset.page = current
    this.pagination.dataset.total = total
  }

  _enableFilters() {
    $('#promotions-items-filter-form').find('input[type="submit"]').removeAttr('disabled')
  }
}
