import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['template', 'items']

  get validationController() {
    return this.application.getControllerForElementAndIdentifier(this.element, 'validation')
  }

  connect() {
    this._disableWeightFields()
  }

  addAssociation(event) {
    event.preventDefault()

    const template = this.templateTarget.innerHTML
    const dataset = event.target.dataset
    const content = this.fillOutTemplate(template, dataset)
    const items = [...document.querySelectorAll('tbody[data-target=\'items-additions.items\'] tr')]
    const item = items.find(row => dataset.id === row.getAttribute('data-item-id'))

    item ? this.sumAmount(dataset, item) : this.addNewItem(dataset, items, content)

    this._disableWeightFields()
    this.validationController.validate()
  }

  sumAmount(dataset, item) {
    const input = item.querySelectorAll('input.form-control')[0]
    input.value = parseFloat(input.value) + parseFloat(dataset.quantity)
  }

  addNewItem(dataset, items, content) {
    const itemAfter = items.find(row =>
      new Date(dataset.deliveryDate).getTime() < new Date(row.getAttribute('data-delivery-date')).getTime()
    )

    if (itemAfter) {
      itemAfter.insertAdjacentHTML('beforebegin', content)
    } else {
      this.itemsTarget.insertAdjacentHTML('beforeend', content)
    }
  }

  fillOutTemplate(template, dataset) {
    return template.replace(/NEW_RECORD/g, new Date().getTime())
            .replace(/ITEM_ID/g, dataset.id)
            .replace(/ITEM_DELIVERY_DATE/g, dataset.deliveryDate)
            .replace(/ITEM_QUANTITY/, dataset.quantity || '0')
            .replace(/ITEM_WEIGHT/g, dataset.weight)
            .replace(/ITEM_UNIT_NETTO_PRICE/, dataset.nettoPrice)
            .replace(/TOTAL_WEIGHT/g, this._calculateWeight(dataset))
            .replace(/ITEM_UNIT/g, dataset.unit)
            .replace(/ITEM_NAME/g, dataset.name)
            .replace(/ITEM_NETTO_PRICE/g, dataset.nettoPrice)
            .replace(/ITEM_BRUTTO_PRICE/g, dataset.bruttoPrice)
            .replace(/ITEM_VAT_PERCENTAGE/g, dataset.vatPercentage)
            .replace(/ITEM_PACKAGE/g, dataset.package)
  }

  removeAssociation(event) {
    event.preventDefault()

    const wrapper = event.target.closest('.nested-fields')

    if (wrapper.dataset.newRecord == 'true') {
      wrapper.remove()
    } else {
      wrapper.querySelector('input[name*=\'destroy\']').value = 1
      wrapper.querySelector('input[name*=\'amount\']').value = 0
      wrapper.querySelector('input[name*=\'amount\']').classList.remove('is-invalid')
      wrapper.style.display = 'none'
    }

    this.validationController.validate()
  }

  _calculateWeight(dataset) {
    return (parseInt(dataset.quantity, 10) * parseFloat(dataset.weight)).toFixed(2)
  }

  _disableWeightFields() {
    const inputs = [...document.querySelectorAll('input.weight-field')].filter(input => {
            return (input.getAttribute('data-package') === 'undefined' || input.getAttribute('data-package') === 'false')
          })

    inputs.forEach(field => {
      if (!field.disabled){
        field.setAttribute('disabled', 'true')
      }
    })
  }
}
