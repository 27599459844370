$(document).ready(() => {
  const userRoleHandler = (value) => {
    switch (value) {
      case 'buyer':
        $('#counterparty').show()
        $('#max_discount').hide()
        $('#clients').hide()
        $('#extended-access').show()
        break
      case 'salesman':
        $('#counterparty').hide()
        $('#max_discount').show()
        $('#clients').show()
        $('#extended-access').hide()
        break
      case 'admin':
        $('#counterparty').hide()
        $('#max_discount').show()
        $('#clients').hide()
        $('#extended-access').hide()
        break
      default: 'buyer'
    }
  }

  const selected_role = $('select#user_role option:selected').val()

  userRoleHandler(selected_role)
  $('select#user_role').change((e) => {
    e.preventDefault()
    userRoleHandler(e.target.value)
  })
})
