import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['list', 'filterForm', 'counterpartySelection']

  get listController() {
    return this.application.getControllerForElementAndIdentifier(this.listTarget, 'dynamic-list')
  }

  async addAll() {
    let hasMore = true
    let currentPage = 1

    while (hasMore) {
      hasMore = await this.fetchCounterparties(currentPage)
      currentPage += 1
    }
  }

  markSelectedItems() {
    this.counterpartySelectionTargets.forEach(element => {
      if (this.listController.hasEntry({ id: element.dataset.id })) {
        element.checked = true
      }
    })
  }

  changeCounterpartySelection(event) {
    if (event.target.checked) {
      this.listController.addEntry({ id: event.target.dataset.id, name: event.target.dataset.name })
      this.showToast('success', event.target.dataset.name)
    } else {
      this.listController.removeEntry({ id: event.target.dataset.id })
      this.showToast('danger', event.target.dataset.name)
    }
  }

  async fetchCounterparties(page) {
    return $.get(`/counterparties.json?${$(this.filterFormTarget).serialize()}&page=${page}`)
      .then((data) => {
        data.results.forEach(entry => this.listController.addEntry({ id: entry.id, name: entry.text }))

        return data.pagination.more
      })
  }

  showToast(type, name) {
    const date = Date.now()
    const text = type === 'success' ? 'Dodano' : 'Usunięto'
    $('.toasts-containter-counterparties').append(
      `<div class="toast" id=${date} role="alert" aria-live="assertive" aria-atomic="true" data-delay="4000">
        <div class="toast-header bg-${type} text-white">
          <strong class="mr-auto">${text}</strong>
        </div>
        <div class="toast-body">
          ${name}
        </div>
      </div >`
    )

    const elem = $(`#${date}`)
    elem.toast('show')
    elem.on('hidden.bs.toast', () => {
      elem.remove()
    })
  }
}
