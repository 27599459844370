import { Controller } from 'stimulus'

import { debounce } from 'underscore'

// Number of milliseconds to wait between refreshing the OrderLock
const ORDER_LOCK_DEBOUNCE = 10000

// How many milliseconds before lock expires to show warning
const WARNING_THRESHOLD = 5 * 60 * 1000

// How many milliseconds before lock expires to save the order
const SAVE_ORDER_THRESHOLD = 1 * 60 * 1000

export default class extends Controller {
  static targets = ['warningMessage']

  connect() {
    this._refreshOrderLock()
    this._hideWarning()

    const refresh = debounce(() => this._refreshOrderLock(), ORDER_LOCK_DEBOUNCE)

    $(document).on('mousemove keypress', () => {
      this.data.set('isRefreshing', true)
      this._hideWarning()
      refresh()
    })

    this.expireCheckTimeout = setInterval(() => {
      this._checkExpiry()
    }, 5000)

    $(window).on('beforeunload', () => this.disconnect())
  }

  disconnect() {
    if (this.expireCheckTimeout) {
      clearInterval(this.expireCheckTimeout)
    }

    this._releaseLock()
  }

  get isRefreshing() {
    return this.data.get('isRefreshing') === 'true'
  }

  _refreshOrderLock() {
    fetch(this.orderLockUrl, { method: 'PUT' })
      .then(response => this._handleLockResponse(response))
  }

  _releaseLock() {
    return fetch(this.orderLockUrl, { method: 'DELETE' })
  }

  _handleLockResponse(response) {
    if (response.ok) {
      response.json().then(response => this.data.set('ttl', response.ttl))
      this.data.set('isRefreshing', false)
    } else {
      window.location.href = '/orders'
    }
  }

  get orderLockUrl() {
    return `/orders/${this.data.get('orderId')}/order_lock`
  }

  _checkExpiry() {
    if (this.isRefreshing) {
      return
    }

    const remaining = new Date(this.data.get('ttl')) - new Date()

    if (remaining < WARNING_THRESHOLD) {
      this._showWarning()
    }

    if (remaining < SAVE_ORDER_THRESHOLD) {
      this._saveOrder()
    }
  }

  _saveOrder() {
    $('[name="save_order"]').trigger('click')
  }

  _hideWarning() {
    $(this.warningMessageTarget).hide()
  }

  _showWarning() {
    $(this.warningMessageTarget).show()
  }
}
