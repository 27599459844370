import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'weightField', 'packageField', 'nettoPrice', 'nettoSum']

  get validationController() {
    return this.application.getControllerForElementAndIdentifier(this.element, 'validation')
  }

  setQuantityAndPrices(event) {
    this._setQuantity(event)
    this._setPrices(event)
  }

  setWeight(event) {
    const amount = event.target.value
    const package_weight = event.target.dataset.weight

    this.weightFieldTarget.value =  (amount * package_weight).toFixed(2)

    this.setQuantityAndPrices(event)
  }

  calculatePackagesQuantity(event) {
    const weight = event.target.value
    const package_weight = event.target.dataset.weight
    const quantity =  (weight / parseFloat(package_weight)).toFixed(2).replace(/\.00$/, '')
    this.packageFieldTarget.value = quantity
    this.buttonTarget.dataset.quantity = quantity
    this._validateItemQuantityField(this.packageFieldTarget)
  }

  validateItemQuantity(event) {
    this._validateItemQuantityField(event.target)
  }

  clearField() {
    this.weightFieldTarget.value = ''
  }

  _setQuantity(event) {
    const quantity = event.target.value

    this.buttonTarget.dataset.quantity = quantity
  }

  _setPrices(event) {
    const price = parseFloat(this.nettoPriceTarget.dataset.value.replace(',', '.'))

    if (event.target.dataset.unit === 'szt') {
      const amount = event.target.value
      this.nettoSumTarget.innerHTML = (amount * price).toFixed(2) + ' zł'
    } else {
      const amount = parseFloat(this.weightFieldTarget.value.replace(',', '.'))
      this.nettoSumTarget.innerHTML = (amount * price).toFixed(2) + ' zł'
    }
  }

  _validateItemQuantityField(field) {
    this.validationController.validate_numerical_field(field)

    if (field.classList.contains('is-invalid')) {
      this.buttonTarget.disabled = true
    } else {
      this.buttonTarget.disabled = false
    }
  }
}
