import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modalLink', 'priceField']

  initialize() {
    this.cache = {}
    this.pendingRequests = {}
  }

  connect() {
    this.baseModalLink = this.modalLinkTarget.href
    const initialOption = this.element.querySelector('input[type="radio"]:checked').value

    this._setModalLink(initialOption)
  }

  reload(event) {
    const documentType = event.target.value
    $('input[name*=\'current-document-type\']').val(documentType)
    this._setModalLink(documentType)

    this.priceFieldTargets.forEach(priceField => {
      this._queue(documentType, priceField.dataset.itemId)
    })

    Promise.all(this._fetch_from_api()).then(() => {
      this.pendingRequests = {}

      this.priceFieldTargets.forEach(priceField => {
        const prices = this._fetch_from_cache(documentType, priceField.dataset.itemId)
        const price = prices[`${priceField.dataset.priceType}_price`]

        priceField.dataset.basePrice = price

        if (priceField.dataset.updateContent === 'yes') {
          priceField.innerHTML = price
        }
      })

      this.dynamicFieldsController.multiply()
    })
  }

  get counterpartyId() {
    return this.data.get('counterpartyId')
  }

  get dynamicFieldsController() {
    return this.application.getControllerForElementAndIdentifier(this.element, 'dynamic-fields')
  }

  _setModalLink(documentType) {
    this.modalLinkTarget.href = `${this.baseModalLink}&document_type=${documentType}`
  }

  _fetch_from_cache(documentType, id) {
    return this.cache[documentType] && this.cache[documentType][id]
  }

  _queue(documentType, id) {
    if (this._fetch_from_cache(documentType, id) === undefined) {
      this.pendingRequests[documentType] = this.pendingRequests[documentType] || new Set()
      this.pendingRequests[documentType].add(id)
    }
  }

  _fetch_from_api() {
    return $.map(this.pendingRequests, (ids, documentType) => {
      const payload = {
        document_type: documentType,
        counterparty_id: this.counterpartyId,
        'item_ids[]': Array.from(ids)
      }

      return $.get(
        '/items/prices',
        payload,
        data => $.each(data, (id, prices) => this._add_to_cache(documentType, id, prices))
      )
    })
  }

  _add_to_cache(documentType, id, prices) {
    this.cache[documentType] = this.cache[documentType] || {}
    this.cache[documentType][id] = prices
  }
}
