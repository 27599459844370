import { Controller } from 'stimulus'

/**
 * @example
 * // example.html
 * <div id="things-list" data-controller="dynamic-list" data-target="dynamic-list.list">
 *   <template data-target="dynamic-list.template">
 *     <div>
 *       <p>id: ENTRY_ID</p>
 *       <p>other_attr: ENTRY_OTHER_ATTR</p>
 *     </div>
 *   </template>
 * </div>
 *
 * // somewhere_else.js
 * const controller = this.application.getControllerForElementAndIdentifier($('#things-list'), 'dynamic-list')
 * controller.addEntry({ id: '347832', other_attr: 'Hello' })
 */
export default class extends Controller {
  static targets = [
    'template',       // Template for the list entries
    'list',           // Container for the list entries
    'initialElement', // Elements to initialize with
  ]

  initialize() {
    this.entries = {}
  }

  connect() {
    this.initialElementTargets.forEach(element => {
      this.addEntry(JSON.parse(element.dataset.entry))
    })
  }

  /**
   * Action to remove given element.
   *
   * It has to be attached to element which has 'entry-id' in it's dataset.
   *
   * @example
   * <div data-entry-id="64" data-action="click->dynamic-list.remove">X</div>
   */
  remove(event) {
    event.preventDefault()
    this.removeEntry({ id: event.currentTarget.dataset.entryId })
  }

  hasEntry(entry) {
    return this.entries[entry.id] !== undefined
  }

  clear() {
    $.each(this.entries, (_index, entry) => entry.element.remove())
    this.entries = {}
  }

  addEntry(entry) {
    if (this.hasEntry(entry)) {
      return false
    }

    const template = this.prepareTemplate(entry)
    const htmlElement = this.listTarget.appendChild(template)

    this.entries[entry.id] = { data: entry, element: htmlElement }

    return true
  }

  removeEntry(entry) {
    if (!this.hasEntry(entry)) {
      return false
    }

    this.entries[entry.id].element.remove()
    delete this.entries[entry.id]

    return true
  }

  prepareTemplate(entry) {
    const clone = this.templateTarget.content.cloneNode(true).children[0]
    let html = clone.innerHTML

    Object.keys(entry).forEach(key => {
      let templatePlaceholder = `ENTRY_${key.toUpperCase()}`
      let replace_with

      if (key.match(/cents/)) {
        replace_with = `${(entry[key] / 100).toFixed(2)} zł`
      } else
      if (key === 'unit') {
        replace_with = entry['package'] ? 'opakowanie' : entry[key]
      }
      else {
        replace_with = entry[key]
      }

      if (key === 'package_weight') { templatePlaceholder = 'ENTRY_P_WEIGHT' }

      if (replace_with === null || replace_with === undefined) {
        replace_with = ''
      }

      html = html.replaceAll(new RegExp(templatePlaceholder, 'g'), replace_with)
    })

    clone.innerHTML = html

    return clone
  }
}
