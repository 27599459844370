import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['placeOrderButton', 'saveButton']

  validate() {
    document.querySelectorAll('.validate-numeric').forEach(field => {
      this.validate_numerical_field(field)
    })

    this._validate_form()
  }

  validate_numerical_field(field) {
    const value = field.value.trim()
    const regexString = ['szt', 'opakowanie'].includes(field.dataset.unit) ? '^[0-9]+$' : '^[0-9]*[.,]?[0-9]+$'
    const regex = new RegExp(regexString)

    !regex.test(value) ? field.classList.add('is-invalid') : field.classList.remove('is-invalid')
  }

  _validate_form() {
    if (document.querySelectorAll('.is-invalid').length !== 0) {
      this.saveButtonTarget.disabled = true
      this.placeOrderButtonTarget.disabled = true
    } else {
      this.saveButtonTarget.disabled = false
      this.placeOrderButtonTarget.disabled = false
    }
  }
}
