import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.render()
  }

  render() {
    this.element.innerHTML = '<nav><ul class="pagination"></ul></nav>'

    if (this.total <= 1) {
      return
    }

    const list = $(this.element).find('.pagination')[0]

    this.appendIf(list, () => this.currentPage !== 1, () => this._createGoToFirstElement())
    this.appendIf(list, () => this.currentPage > 1, () => this._createPreviousElement())
    this.appendIf(list, () => this.currentPage > 5, () => this._createTruncateElement())

    const min = Math.max(this.currentPage - 4, 1)
    const max = Math.min(this.currentPage + 4, this.total)

    for (let index = min; index <= max; index++) {
      list.appendChild(this._createPageElement(index))
    }

    this.appendIf(list, () => (this.total - this.currentPage) > 4, () => this._createTruncateElement())
    this.appendIf(list, () => this.currentPage < this.total, () => this._createNextElement())
    this.appendIf(list, () => this.currentPage !== this.total, () => this._createGoToLastElement())
  }

  changePage(source) {
    const page = parseInt(source.currentTarget.dataset.page, 10)

    if (page === this.currentPage) {
      return
    }

    const event = document.createEvent('CustomEvent')
    event.initCustomEvent('page-changed', true, true, null)
    this.element.dataset.page = page

    this.element.dispatchEvent(event)
  }

  get currentPage() {
    return parseInt(this.element.dataset.page, 10)
  }

  get total() {
    return parseInt(this.element.dataset.total, 10)
  }

  appendIf(list, conditionFunction, elementBuilder) {
    if (conditionFunction()) {
      list.appendChild(elementBuilder())
    }
  }

  _createGoToFirstElement() {
    const element = this._createBasePageItem()
    element.appendChild(this._createLink(1, this.element.dataset.translationsFirst))

    return element
  }

  _createPreviousElement() {
    const element = this._createBasePageItem()
    element.appendChild(this._createLink(this.currentPage - 1, this.element.dataset.translationsPrevious))

    return element
  }

  _createTruncateElement() {
    const element = document.createElement('li')
    element.className = 'page-item disabled'

    const link = document.createElement('a')
    link.className = 'page-link'
    link.setAttribute('href', '#')
    link.innerHTML = this.element.dataset.translationsTruncate

    element.appendChild(link)

    return element
  }

  _createPageElement(page) {
    const element = this._createBasePageItem(this.currentPage === page)
    element.appendChild(this._createLink(page, page))

    return element
  }

  _createNextElement() {
    const element = this._createBasePageItem()
    element.appendChild(this._createLink(this.currentPage + 1, this.element.dataset.translationsNext))

    return element
  }

  _createGoToLastElement() {
    const element = this._createBasePageItem()
    element.appendChild(this._createLink(this.total, this.element.dataset.translationsLast))

    return element
  }

  _createLink(page, text) {
    const element = document.createElement('a')
    element.className = 'page-link'
    element.setAttribute('href', '#')
    element.setAttribute('data-action', 'click->pagination#changePage')
    element.setAttribute('data-page', page)
    element.onclick = event => event.preventDefault()
    element.innerHTML = text

    return element
  }

  _createBasePageItem(active = false) {
    const element = document.createElement('li')
    element.className = `page-item ${active ? 'active' : ''}`

    return element
  }
}
