import { Controller } from 'stimulus'


export default class extends Controller {
  get validationController() {
    return this.application.getControllerForElementAndIdentifier(this.element, 'validation')
  }

  connect() {
    this.multiply()
  }

  multiply() {
    document.querySelectorAll('.nested-fields').forEach(row => {
      this._multiply_row(row)
    })
  }

  _multiply_row(row) {
    this._multiply_netto_price(row)
    this._multiply_weight(row)
    this._multiply_brutto_price(row)
  }

  calculate_quantity(event) {
    const field = event.target
    const weight = field.value
    const package_weight = field.dataset.weight
    const wrapper = event.target.closest('.nested-fields')
    const amount_field = wrapper.querySelector('input[name*=\'amount\']')
    amount_field.value = (weight / package_weight).toFixed(2).replace(/\.00$/, '')

    this.validationController.validate_numerical_field(amount_field)
  }

  _multiply_netto_price(row) {
    const regex = new RegExp('^[0-9]*[.,]?[0-9]+')
    const nettoField = row.querySelector('.netto-price-field')
    const amount = parseFloat(row.querySelector('input[name*=\'amount\']').value)
    const weight = parseFloat(row.querySelector('.weight-field').dataset.weight.match(regex)[0].replace(',', '.'))
    const price = parseFloat(row.dataset.baseNettoPrice.match(regex)[0].replace(',', '.'))
    const amount_for_calculation = (row.dataset.package == 'true') ? (amount * weight) : amount

    nettoField.innerHTML = `${this._multiply_values(price, amount_for_calculation)} zł`
  }

  _multiply_weight(row) {
    const regex = new RegExp('^[0-9]*[.,]?[0-9]+')
    const weightField = row.querySelector('input[name*=\'weight\']')
    const amount = parseFloat(row.querySelector('input[name*=\'amount\']').value)
    const weight = parseFloat(weightField.dataset.weight.match(regex)[0].replace(',', '.'))

    weightField.value = this._multiply_values(weight, amount)
  }

  _multiply_brutto_price(row) {
    const regex = new RegExp('^[0-9]*[.,]?[0-9]+')
    const bruttoField = row.querySelector('.brutto-price-field')
    const documentType = $('input[name*=\'current-document-type\']').val()
    if (documentType === 'receipt') {
      const amount = parseFloat(row.querySelector('input[name*=\'amount\']').value)
      const weight = parseFloat(row.querySelector('.weight-field').dataset.weight.match(regex)[0].replace(',', '.'))
      const price = parseFloat(row.dataset.baseBruttoPrice.match(regex)[0].replace(',', '.'))
      const amount_for_calculation = (row.dataset.package == 'true') ? (amount * weight) : amount
      const newValue = this._multiply_values(price, amount_for_calculation)

      bruttoField.innerHTML = `${newValue} zł`
    } else {
      const vatPercentage = parseFloat(row.dataset.vatPercentage) * 0.01
      const nettoPrice = parseFloat(row.querySelector('.netto-price-field').textContent.match(regex)[0].replace(',', '.'))
      const bruttoMultiplier = vatPercentage + 1
      const newValue = this._multiply_values(nettoPrice, bruttoMultiplier)

      bruttoField.innerHTML = `${newValue} zł`
    }
  }

  _multiply_values(base, amount) {
    const result = (base * amount).toFixed(2)
    if (isNaN(result)) {
      return 0
    }

    return result
  }
}
